import React, { useEffect, useState, Suspense, startTransition, useRef } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Routes, Route ,Navigate, useLocation} from "react-router-dom";
import './App.css';
import Menu from './pages/menu/Menu';
import Menuitem from './pages/menuitem/Menuitem';
import Location from './pages/location/Location';
import Detail from './pages/detail/Detail';
import ReadMore from './pages/readmore/ReadMore';
import ViewAll from './pages/view_all/ViewAll';
import { fetchDefaultLocation } from './apiaction/actions';
import { useDispatch, useSelector } from 'react-redux';
import Home from './pages/home/Home';
import { gql, useQuery } from '@apollo/client';
const GET_LOCATIONS = gql`
  {
    data {
      all_location {
        id
        url
        banners
        headermenu
        section
        our_menu
        theme_color
        business_hours
        carryout_hours
      }
    }
  }
`;
function App() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const firstLocation = window.location.pathname.toLowerCase();
  const secretKey = '0Xi82iggZJRlO1Tg6Jo3J4IrHw7zUb';
  localStorage.setItem('items', secretKey);
  const subsecretKey = '0i2gZROT6oJIH7U';
  localStorage.setItem('subitems', subsecretKey);
  const [mainLocation, setMainLocation] = useState('');
  const [res_id, setresid] = useState('');
  const [mainTheme, setMainTheme] = useState({});
  const [allLocation, setAllLocation] = useState([]);
  const [AllMainLocation, setAllMainLocation] = useState([]);
  const [banner, setBanner] = React.useState([]);
  const [getreslistData, setGetreslistData] = React.useState([]);
  const [home, setHome] = useState([]);
  const [headermenu, setHeadermenu] = React.useState([]);
  const [ourMenu, setOurMenu] = React.useState([]);
  const [getLocation, setGetLocation] = React.useState([]);
  const [businessHours, setBusinessHours] = React.useState([]);
  const [carryoutHours, setCarryoutHours] = React.useState([]);
  const [footerData, setFooterData] = React.useState([]);
  const [themeData, setThemeData] = React.useState([]);
  const [alldata, setAlldata] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [mainLocationWise, setmainLocationWise] = useState('');
  const [allLocationNavigtion, setmainLocationWiseurl] = useState('');
  const [locationFound, setLocationFound] = useState(false);
  const [AllApiData,setAll] = useState([])
  // menu page 
  const [webmenuitemData1,setwebmenuitemData1] = useState([]);
  const [webmenuitemDataSlot11,setwebmenuitemDataSlot11] = useState([]);
  const [webmenuitemDataSlot21,setwebmenuitemDataSlot21] = useState([]);
  const [webmenuitemDataSlot31,setwebmenuitemDataSlot31] = useState([]);
  const [loading1, setLoading1] = useState(true);
  // End menu page
  const hasFetchedData = useRef(false);

  //var web_url = 'https://www.thaispicekc.com';
  var web_url = window.location.origin;
  // console.log(web_url);

  useEffect(() => {
    const fetchDataAll = async () => {
      await axios.get(`${web_url}/es-admin/api/main_home`, {
        params: {
          query: GET_LOCATIONS
        }
      })
      .then(response => {
        setData(response);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
    }
    fetchDataAll();
  }, []);
  useEffect(() => {
    if (data && data.data && data.data.all_location && Array.isArray(data.data.all_location)) {
      if(firstLocation == '/'){
        data.data.all_location.forEach(location => {
          if (location.id === data.data.location.id) {
            setmainLocationWise(location.id);
            setmainLocationWiseurl(location.url);
            setBanner(location.banners);
            setHeadermenu(location.headermenu);
            setHome(location.section);
            setAlldata(location);
            setGetLocation(location);
            setOurMenu(location.our_menu);
            setFooterData(location);
            setThemeData(location.theme_color);
            setBusinessHours(location.business_hours);
            setCarryoutHours(location.carryout_hours);
            setMainTheme(location.theme);
            setresid(location.eatstations_res_id);
            localStorage.setItem('resid', location.eatstations_res_id);
            setLocationFound(true);          
          }
        });
      }
      else{
        data.data.all_location.forEach(location => {
          const locationUrl = `/${location.url}`.toLowerCase();
          const locationUrllocation = `/${location.url}/location`.toLowerCase();
          const locationUrlmenu = `/${location.url}/menu`.toLowerCase();
          const locationUrlmenuslot1 = `/${location.url}/menu/slot=1`.toLowerCase();
          const locationUrlmenuslot2 = `/${location.url}/menu/slot=2`.toLowerCase();
          const locationUrlmenuslot3 = `/${location.url}/menu/slot=3`.toLowerCase();
          const locationUrlreadmore = `/${location.url}/readmore`.toLowerCase();
          const locationUrlviewall = `/${location.url}/viewall`.toLowerCase();
          var lastPart = window.location.href.split("/").pop();
          const locationUrlmenuitem = `/${location.url}/menuitem/${lastPart}`.toLowerCase();         
          if (firstLocation === locationUrl || firstLocation === locationUrllocation || firstLocation === locationUrlmenu || firstLocation === locationUrlmenuslot1 || firstLocation === locationUrlmenuslot2 || firstLocation === locationUrlmenuslot3 || firstLocation === locationUrlreadmore || firstLocation === locationUrlviewall || firstLocation === locationUrlmenuitem) {
            setmainLocationWise(location.id);
            setmainLocationWiseurl(location.url);
            setBanner(location.banners);
            setHeadermenu(location.headermenu);
            setHome(location.section);
            setAlldata(location);
            setGetLocation(location);
            setOurMenu(location.our_menu);
            setFooterData(location);
            setThemeData(location.theme_color);
            setBusinessHours(location.business_hours);
            setCarryoutHours(location.carryout_hours);
            setMainTheme(location.theme);             
            setresid(location.eatstations_res_id);
            localStorage.setItem('resid', location.eatstations_res_id);
            setLocationFound(true);
          }
        });
      }
    }
  }, [data, firstLocation, locationFound]);
  // console.log('alldata',alldata);
  // console.log('resid',res_id);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const responseAll = await axios.get('https://www.thaispicekc.com/es-admin/api/main_home');
  //       startTransition(() => {
  //         setAll(responseAll.data);
  //         // setLoading(false);
  //       });
  //     } catch (error) {
  //       console.error('Error fetching main location data: ', error);
  //     }
  //   };
  //   fetchData();
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${web_url}/es-admin/api/default_location_get`
        );
        startTransition(() => {
          setMainLocation(response.data.location.id);
          setAllLocation(response.data.location);
          setAllMainLocation(response.data);
        });
      } catch (error) {
        console.error('Error fetching main location data: ', error);
      }
    };
    fetchData();
  }, []);
  // const res_id = getLocation?.eatstations_res_id;
  // console.log(res_id);
  useEffect(() => {
    const fetchWebData = async () => {
      if(res_id != '' || res_id){
        
            await axios.get('https://www.eatstations.com/api/get_res_list',{
              params: {res_id: res_id}
            })
            .then(response => {
              setGetreslistData(response.data);
            }) 

            // main slot get
            const webmenuitemResponse = await axios.get(`https://www.eatstations.com/api/web_menu_item/${res_id}`);
            setwebmenuitemData1(webmenuitemResponse.data);
            setLoading1(false);

            // main slot1 get
            await axios.get(`https://www.eatstations.com/api/web_menu_item/${res_id}`,{
              params: {slot: 1}
            })
            .then(response11 => {
              setwebmenuitemDataSlot11(response11.data);
              setLoading1(false);
            }) 

            // main slot2 get
            await axios.get(`https://www.eatstations.com/api/web_menu_item/${res_id}`,{
              params: {slot: 2}
            })
            .then(response21 => {
              setwebmenuitemDataSlot21(response21.data);
              setLoading1(false);
            })

            // main slot3 get
            await axios.get(`https://www.eatstations.com/api/web_menu_item/${res_id}`,{
              params: {slot: 3}
            })
            .then(response31 => {
              setwebmenuitemDataSlot31(response31.data);
              setLoading1(false);
            })
          }
      };
      fetchWebData();
  }, [res_id]);
  
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   startTransition(() => {
  //     dispatch(fetchDefaultLocation());
  //   });
  // }, [dispatch]);
  // const {
  //   webmenuitemData1,
  //   // webmenuitemDataSlotDefault,
  //   webmenuitemDataSlot11,
  //   webmenuitemDataSlot21,
  //   webmenuitemDataSlot31,
  //   loading1,
  // } = useSelector(state => state.api);

  const substoredEncryptedKey = localStorage.getItem('subitems');
  const storedEncryptedKey = localStorage.getItem('items');
  let webLocationLength;
    home?.map((homeDate)=>{
        if(homeDate.section_id === 6){
            webLocationLength = homeDate.data.length;
        }
  });
  if (loading === true) {
    return (
      <>
        <div><div style={{ width: '100vw', height: '100vh', backgroundImage: `url(${process.env.PUBLIC_URL + '/loader_image.jpg'})`, backgroundSize: 'cover' }}></div></div>
        <Suspense fallback={<div><div style={{ width: '100vw', height: '100vh', backgroundImage: `url(${process.env.PUBLIC_URL + '/loader_image.jpg'})`, backgroundSize: 'cover' }}></div></div>}></Suspense>
      </>
    )
  }
  else if(loading == false && allLocation && allLocation.url && allLocationNavigtion){
    return (
      <>
      <Helmet>
        {AllMainLocation.meta_tag?.map((tag, index) => (
          <meta key={index} name={tag.name} content={tag.content} />
        ))}
        {AllMainLocation.meta_tag?.map((tag, index) => (
          <meta key={index} name={'description'} content={tag.description} />
        ))}
        {AllMainLocation.meta_tag?.map((tag, index) => (
          <meta key={index} name={'keywords'} content={tag.keywords} />
        ))}
        {AllMainLocation.meta_tag?.map((tag, index) => (
          <meta key={index} name={'author'} content={tag.author} />
        ))}
        <link rel="icon" href={`${alldata.user?.image}`} />
        <title>{alldata.user?.name}</title>
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path={`/`} index element={<Home home={home} getLocation={getLocation} headermenu={headermenu} businessHours={businessHours} carryoutHours={carryoutHours} alldata={alldata} banner={banner} loading={loading} AllMainLocation={AllMainLocation} themeData={themeData} footerData={footerData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} getreslistData={getreslistData} mainLocation={mainLocation} substoredEncryptedKey={substoredEncryptedKey} storedEncryptedKey={storedEncryptedKey} to={`/`} mainTheme={mainTheme} res_id={res_id}/>}/>
              <Route path={`/`} element={<Home home={home} getLocation={getLocation} headermenu={headermenu} businessHours={businessHours} carryoutHours={carryoutHours} alldata={alldata} banner={banner} loading={loading} AllMainLocation={AllMainLocation} themeData={themeData} footerData={footerData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} getreslistData={getreslistData} mainLocation={mainLocation} substoredEncryptedKey={substoredEncryptedKey} storedEncryptedKey={storedEncryptedKey} mainTheme={mainTheme} res_id={res_id}/>} />
              <Route path={`/${allLocationNavigtion}`} element={<Home home={home} getLocation={getLocation} headermenu={headermenu} businessHours={businessHours} carryoutHours={carryoutHours} alldata={alldata} banner={banner} loading={loading} AllMainLocation={AllMainLocation} themeData={themeData} footerData={footerData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} getreslistData={getreslistData} mainLocation={mainLocation} substoredEncryptedKey={substoredEncryptedKey} storedEncryptedKey={storedEncryptedKey} mainTheme={mainTheme} res_id={res_id}/>} />
              {home?.map((OurBlog) =>(
                <>
                  {OurBlog.section_id == 4 ? (
                    <>
                      {OurBlog.data?.map((OurBlogInner)=>(
                        <Route path={`/${allLocationNavigtion}/detail/${OurBlogInner.id}`} element={<Detail getLocation={getLocation} headermenu={headermenu} alldata={alldata} themeData={themeData} businessHours={businessHours} carryoutHours={carryoutHours} footerData={footerData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} home={home} OurBlogInner={OurBlogInner} loading={loading} substoredEncryptedKey={substoredEncryptedKey} storedEncryptedKey={storedEncryptedKey} mainTheme={mainTheme} webLocationLength={webLocationLength}/>}/>
                      ))}
                    </>
                  ) : null}
                </>
              ))}
              <Route path={`/${allLocationNavigtion}/location`} element={<Location getLocation={getLocation} headermenu={headermenu} alldata={alldata} themeData={themeData} businessHours={businessHours} carryoutHours={carryoutHours} footerData={footerData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} home={home} loading={loading} substoredEncryptedKey={substoredEncryptedKey} storedEncryptedKey={storedEncryptedKey}  mainTheme={mainTheme} webLocationLength={webLocationLength}/>}/>
              <Route path={`/${allLocationNavigtion}/menu`} element={<Menu getLocation={getLocation} headermenu={headermenu} alldata={alldata} themeData={themeData} businessHours={businessHours} carryoutHours={carryoutHours} footerData={footerData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} ourMenu={ourMenu} loading={loading} substoredEncryptedKey={substoredEncryptedKey} storedEncryptedKey={storedEncryptedKey} res_id={res_id} mainTheme={mainTheme} webLocationLength={webLocationLength} webmenuitemData={webmenuitemData1} loading1={loading1}/>}/>
              <Route path={`/${allLocationNavigtion}/menu/slot=1`} element={<Menu getLocation={getLocation} headermenu={headermenu} alldata={alldata} themeData={themeData} businessHours={businessHours} carryoutHours={carryoutHours} footerData={footerData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} ourMenu={ourMenu} loading={loading} substoredEncryptedKey={substoredEncryptedKey} storedEncryptedKey={storedEncryptedKey} res_id={res_id} mainTheme={mainTheme} webLocationLength={webLocationLength} webmenuitemData={webmenuitemDataSlot11} loading1={loading1}/>}/>
              <Route path={`/${allLocationNavigtion}/menu/slot=2`} element={<Menu getLocation={getLocation} headermenu={headermenu} alldata={alldata} themeData={themeData} businessHours={businessHours} carryoutHours={carryoutHours} footerData={footerData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} ourMenu={ourMenu} loading={loading} substoredEncryptedKey={substoredEncryptedKey} storedEncryptedKey={storedEncryptedKey} res_id={res_id} mainTheme={mainTheme} webLocationLength={webLocationLength} webmenuitemData={webmenuitemDataSlot21} loading1={loading1}/>}/>
              <Route path={`/${allLocationNavigtion}/menu/slot=3`} element={<Menu getLocation={getLocation} headermenu={headermenu} alldata={alldata} themeData={themeData} businessHours={businessHours} carryoutHours={carryoutHours} footerData={footerData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} ourMenu={ourMenu} loading={loading} substoredEncryptedKey={substoredEncryptedKey} storedEncryptedKey={storedEncryptedKey} res_id={res_id} mainTheme={mainTheme} webLocationLength={webLocationLength} webmenuitemData={webmenuitemDataSlot31} loading1={loading1}/>}/>
              <Route path={`/${allLocationNavigtion}/readmore`} element={<ReadMore getLocation={getLocation} headermenu={headermenu} alldata={alldata} themeData={themeData} businessHours={businessHours} carryoutHours={carryoutHours} footerData={footerData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} home={home} loading={loading} substoredEncryptedKey={substoredEncryptedKey} storedEncryptedKey={storedEncryptedKey}  mainTheme={mainTheme} webLocationLength={webLocationLength}/>}/>
              <Route path={`/${allLocationNavigtion}/viewall`} element={<ViewAll getLocation={getLocation} headermenu={headermenu} alldata={alldata} themeData={themeData} businessHours={businessHours} carryoutHours={carryoutHours} footerData={footerData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} home={home} loading={loading} substoredEncryptedKey={substoredEncryptedKey} storedEncryptedKey={storedEncryptedKey}  mainTheme={mainTheme} webLocationLength={webLocationLength}/>}/>
              <Route path={`/${allLocationNavigtion}/menuitem/:id`} element={<Menuitem getLocation={getLocation} headermenu={headermenu} alldata={alldata} themeData={themeData} businessHours={businessHours} carryoutHours={carryoutHours} footerData={footerData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} ourMenu={ourMenu} loading={loading} substoredEncryptedKey={substoredEncryptedKey} storedEncryptedKey={storedEncryptedKey} res_id={res_id} mainTheme={mainTheme} webLocationLength={webLocationLength} webmenuitemData={webmenuitemData1} loading1={loading1}/>}/>
              <Route path='*' element={<Navigate to={`/${allLocationNavigtion}`}/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
      </>
    );
  }
}

export default App;